import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../styles/alianzas.css';

const publicUrl = process.env.PUBLIC_URL;

const Alianzas = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
            mirror: true,
        });

        const cards = document.querySelectorAll('.alianza-card');

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio >= 0.5 && entry.isIntersecting) {
                        entry.target.classList.remove('fade-out');
                    } else if (entry.intersectionRatio < 0.5) {
                        entry.target.classList.add('fade-out');
                    }
                });
            },
            {
                threshold: [0, 0.5, 1],
            }
        );

        cards.forEach((card) => observer.observe(card));

        return () => observer.disconnect();
    }, []);

    const alliances = [
        {
            image: `${publicUrl}/media/alianzas/Alianzas.png`,
            link: 'https://www.pearson.com/',
        },
        {
            image: `${publicUrl}/media/alianzas/Alianzas2.png`,
            link: 'https://www.facebook.com/ineclosmochis?mibextid=ZbWKwL',
        },
        {
            image: `${publicUrl}/media/alianzas/ICATSIN.png`,
            link: 'https://www.icatsin.edu.mx/',
        },
        {
            image: `${publicUrl}/media/alianzas/gpo.png`,
            link: 'https://www.gasypetroquimicadeoccidente.com/',
        },
        {
            image: `${publicUrl}/media/alianzas/IMDA.png`,
            link: 'https://www.ahome.gob.mx/category/imda/',
        },
    ];

    return (
        <div className="alianzas-container">
            <img
                src={`${publicUrl}/media/encabezados/alianzas.png`}
                alt="Alianzas"
                className="header-image"
                data-aos="zoom-in"
            />
            <div className="alianzas-grid">
                {alliances.map((alliance, index) => (
                    <a key={index} href={alliance.link} className="alianza-card" target="_blank" rel="noopener noreferrer">
                        <img src={alliance.image} alt={`Alianza ${index + 1}`} className="alianza-image" />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Alianzas;
