import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/Popup.css';

const Popup = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  // Determina si estamos en la sección de prueba (aquí puedes modificar la lógica)
  const isTestSection = window.location.pathname.includes('/prueba'); // Aquí puedes definir tu lógica

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (!isTestSection) {
      setOpen(false);
      const interval = setInterval(() => {
        setOpen(false);
      }, 90000); // 90 segundos
      return () => clearInterval(interval);
    }
  }, [isTestSection]);

  const handleClose = () => {
    setOpen(false);
  };

  const publicUrl = process.env.PUBLIC_URL;

  let imageSrc = '';
  if (isSmallScreen) {
    imageSrc = `${publicUrl}/media/popup/2025.jpg`;
  } else if (isMediumScreen) {
    imageSrc = `${publicUrl}/media/popup/2025.jpg`;
  } else if (isLargeScreen) {
    imageSrc = `${publicUrl}/media/popup/2025.jpg`;
  }

  const message = `Hola, quisiera aprovechar la promoción de su página web`;
  const whatsappUrl = `https://api.whatsapp.com/send?phone=5216681931693&text=${encodeURIComponent(message)}`;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          padding: 0,
          borderRadius: '10px',
          height: 'auto',
          margin: 0,
          overflow: 'hidden',
        },
      }}
    >
      <DialogContent
        style={{
          padding: '0px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 0,
        }}
      >
        <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            overflow: 'hidden',
            padding: '0',
            margin: 0,
          }}
        >
          <img
            src={imageSrc}
            alt="Responsive"
            style={{
              maxWidth: '100%',
              maxHeight: isSmallScreen ? '60vh' : '90vh',
              height: 'auto',
              width: 'auto',
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
            width: '100%',
            padding: '0',
            margin: 0,
          }}
        >
          <a
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: '#000',
              backgroundColor: '#25D366',
              padding: '10px 20px',
              borderRadius: '5px',
              fontSize: '16px',
              fontWeight: 'bold',
              width: '100%',
              boxSizing: 'border-box',
              justifyContent: 'center',
            }}
          >
            <img
              src={`${publicUrl}/media/logo/wpp.png`}
              alt="WhatsApp"
              style={{ width: '24px', height: '24px', marginRight: '8px' }}
            />
            <span style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              Haz click ya para aprovechar la promoción
            </span>
          </a>
        </div>
      </DialogContent>
    </Dialog>
  ); 
};

export default Popup;
